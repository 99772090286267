import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { analyticsTags } from '../../utils';
import s from './PromoVideoPlayer.module.scss';
import PlayButton from './img/play-circle.svg';

const VideoPlayer = dynamic(() => import('../VideoPlayer'), {
  ssr: false,
});

const propTypes = {
  videoData: PropTypes.shape({
    tags: PropTypes.array,
    contentfulId: PropTypes.string,
    displayTitle: PropTypes.string,
    contentTypeId: PropTypes.string,
    duration: PropTypes.string,
    displayTag: PropTypes.array,
    activationDate: PropTypes.string,
    videoFileSource: PropTypes.string,
    mainImage: PropTypes.shape({}),
    description: PropTypes.string,
  }).isRequired,
  showOverlays: PropTypes.bool,
};

const PromoVideoPlayer = ({ videoData, showOverlays = true }) => {
  const [customOverlays] = useState([
    {
      id: `content-overlay-${videoData.contentfulId}`,
      start: 'loadstart',
      end: 'play',
      align: 'all',
    },
    {
      id: `end-overlay-${videoData.contentfulId}`,
      start: 'ended',
      end: 'playing',
      align: 'all',
    },
  ]);
  const videoDataObject = useMemo(() => {
    return {
      contentId: videoData.contentfulId,
      contentName: videoData.displayTitle,
      contentType: videoData.contentTypeId,
      contentDisplayTag: analyticsTags(videoData.displayTag),
      contentTags: analyticsTags(videoData.tags),
      contentDuration: videoData.duration,
      activationDate: videoData.activationDate,
    };
  }, [videoData]);

  const analyticsData = useMemo(() => {
    return {
      contentId: videoData.contentfulId,
      contentName: videoData.displayTitle,
      contentType: videoData.contentTypeId,
      contentDisplayTag: analyticsTags(videoData.displayTag),
      contentTags: analyticsTags(videoData.tags),
      contentDuration: videoData.duration,
      activationDate: videoData.activationDate,
    };
  }, [videoData]);

  return (
    <>
      <div
        className={s.root}
        style={{
          '--play-button': `url(${PlayButton})`,
        }}
      >
        <div className={s.videoPlayer}>
          {videoData.videoFileSource && (
            <VideoPlayer
              video={videoData.videoFileSource}
              image={`https:${videoData.mainImage?.image?.file?.url}`}
              imageWidth={1280}
              title={videoData.displayTitle || ''}
              duration={videoData.duration || ''}
              videoData={videoDataObject}
              analyticsData={analyticsData}
              customOverlays={customOverlays}
            >
              {showOverlays && videoData.displayTitle && videoData.displayTitle.trim() && (
                <>
                  <div id={`content-overlay-${videoData.contentfulId}`}>
                    <div className={s.overlay}>
                      <div className={s.title}>{videoData.displayTitle}</div>
                      <div className={s.description}>{videoData.description}</div>
                    </div>
                  </div>
                  <div id={`end-overlay-${videoData.contentfulId}`}>
                    <div className={s.overlay}>
                      <div className={s.title}>{videoData.displayTitle}</div>
                      <div className={s.description}>{videoData.description}</div>
                    </div>
                  </div>
                </>
              )}
            </VideoPlayer>
          )}
        </div>
      </div>
    </>
  );
};

PromoVideoPlayer.propTypes = propTypes;
export default PromoVideoPlayer;
