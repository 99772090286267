
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import AnchorLink from '../../../../components/AnchorLink';
import Image from '../../../../components/Image';
import Button from '../../../../components/Button';
import s from './CollectionSpotlight.module.scss';
import { trackClickEvent } from '../../../../utils/analytics';
import { analyticsTags } from '../../../../utils';
import { findHubName } from '../../../../utils/hubs';
import { curatedImageSet } from '../../../../utils/optimizeImage';
const propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    externalId: PropTypes.string,
    tags: PropTypes.array,
    contentTypeId: PropTypes.string,
    contentfulId: PropTypes.string,
    color: PropTypes.string,
    btnLabel: PropTypes.string
};
const CollectionSpotlight = ({ title = 'Editors Pick', subTitle = '', description = '', image = '', externalId = '', tags = [], contentTypeId = '', contentfulId = '', color = '', btnLabel = 'READ FEATURE', }) => {
    const { componentRef, verticalIndex } = useVerticalIndex();
    const hubName = findHubName(tags);
    const subTitleId = `hub_curated_sub_title_${subTitle}`;
    const btnId = `hub_curated_btn_${btnLabel}`;
    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });
    const hubLink = `/${hubName}/${externalId}`;
    return (<section className={s.root} ref={ref}>
      <div className={s.container} ref={componentRef}>
        <div className={[s.imgContainer, inView ? s.animate : ''].join(' ')}>
          <AnchorLink title="Read Feature" onClick={() => {
            trackClickEvent({
                destination_url: hubLink,
                label: 'Read Feature',
                content_name: subTitle,
                content_type: contentTypeId,
                content_id: contentfulId,
                content_tags: analyticsTags(tags),
                location: 'Collection',
                vertical_index: verticalIndex,
                horizontal_index: 0
            });
        }} href={hubLink} aria-labelledby={`${subTitleId} ${btnId}`}>
            <Image imageSet={curatedImageSet(image)} alt={subTitle} gradient={false} lazy contain/>
          </AnchorLink>
        </div>
        <div className={[s.contentRight, inView ? s.animate : ''].join(' ')} style={{ backgroundColor: color }}>
          <article className={s.textContainer}>
            <p className={s.eyebrow}>{title}</p>
            <h3 className={s.title} id={subTitleId}>
              {subTitle}
            </h3>
            <p className={s.description}>{description}</p>
            <div className={s.featureBtn}>
              <AnchorLink href={hubLink} aria-labelledby={`${subTitleId} ${btnId}`}>
                <Button className={s.btn} label={btnLabel} id={btnId} level="secondary2" tabIndex="-1" onClick={() => {
            trackClickEvent({
                destination_url: hubLink,
                label: btnLabel,
                content_name: subTitle,
                content_type: contentTypeId,
                content_id: contentfulId,
                content_tags: analyticsTags(tags),
                location: 'Collection',
                vertical_index: verticalIndex,
                horizontal_index: 0
            });
        }}/>
              </AnchorLink>
            </div>
          </article>
        </div>
      </div>
    </section>);
};
CollectionSpotlight.propTypes = propTypes;
export default CollectionSpotlight;

    async function __Next_Translate__getStaticProps__191c29dd648__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionSpotlight/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c29dd648__ as getStaticProps }
  